import "./App.css";
import Anasayfa from "./Pages/Anasayfa";
import BilgiBank from "./Pages/BilgiBank";
import Hizmetlerimiz from "./Pages/Hizmetlerimiz";
import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TeklifAl from "./Pages/TeklifAl";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" Component={Anasayfa} />
        <Route exact path="/hizmetlerimiz" Component={Hizmetlerimiz} />
        <Route exact path="/bilgibankasi" Component={BilgiBank} />
        <Route exact path="/teklifal" Component={TeklifAl} />
      </Routes>
    </Router>
  );
}

export default App;
