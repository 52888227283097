import React from "react";
import BilgibankHero from "../components/Bilgibank/BilgibankHero";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BilgibankContent from "../components/Bilgibank/BilgibankContent";

const BilgiBank = () => {
  return (
    <>
      <Navbar />
      <BilgibankHero />
      <BilgibankContent />
      <Footer />
    </>
  );
};

export default BilgiBank;
