import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const TeklifForm = () => {
  const ref = useRef();
  const [success, setSuccess] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm("service_x1b40pe", "template_eol0vdt", ref.current, {
        publicKey: "VXq9ok_yCyuuzL4pg",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setSuccess(true);
        },
        (error) => {
          console.log("FAILED...", error.text);
          setSuccess(false);
        }
      );
  };
  return (
    <div>
      <section className="bg-[#dce8ed]">
        <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:py-20 lg:px-8">
          <div className="max-w-2xl lg:max-w-4xl mx-auto text-center">
            <h2
              className="text-4xl font-extrabold text-gray-900"
              id="contactUs"
            >
              Teklif Al
            </h2>
            <p className="mt-3 text-lg text-gray-500">
              Sizin için en iyi teklifi bulmak, işimiz.
            </p>
          </div>
          <div className="mt-8 lg:mt-20 p-10">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <div className="max-w-full mx-auto rounded-lg overflow-hidden">
                  <form
                    className="max-w-md mx-auto"
                    ref={ref}
                    onSubmit={handleSubmit}
                  >
                    <div className="relative z-0 w-full mb-5 mt-5 group">
                      <input
                        type="email"
                        name="email"
                        id="floating_email"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-[#145a7d] dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required=""
                      />
                      <label
                        htmlFor="floating_email"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Email Adresi
                      </label>
                    </div>
                    <div className="md:gap-6">
                      <div className="relative z-0 w-full mb-5 group">
                        <input
                          type="text"
                          name="name"
                          id="floating_first_name"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-[#145a7d] dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          required=""
                        />
                        <label
                          htmlFor="floating_first_name"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                          Ad Soyad
                        </label>
                      </div>
                    </div>
                    <div className="md:gap-6">
                      <div className="relative z-0 w-full mb-5 group">
                        <input
                          type="number"
                          name="telefon"
                          id="floating_phone"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-[#145a7d] dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          required=""
                        />
                        <label
                          htmlFor="floating_phone"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                          Telefon Numarası (123-456-7890)
                        </label>
                      </div>
                    </div>
                    <div className="md:gap-6">
                      <div className="relative z-0 w-full mb-5 group">
                        <textarea
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          name="message"
                          id="floating_phone"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-[#145a7d] dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          required=""
                        />
                        <label
                          htmlFor="floating_phone"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                          Mesajınız
                        </label>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="text-white mb-1 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-[#145a7d] dark:hover:bg-blue-950 transition dark:focus:ring-blue-900"
                    >
                      Teklif Al
                    </button>
                    {success && (
                      <div className="mt-2 min-h-[20px] flex items-center justify-center">
                        Mesajınız Gönderilmiştir. En kısa sürede geri dönüş
                        yapacağız..
                      </div>
                    )}
                  </form>
                </div>
              </div>
              <div className="rounded-lg overflow-hidden order-none sm:order-first">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d670.3972627863237!2d30.728900535653008!3d36.939008880382104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c385940cd7fa6f%3A0x59cb2847d18e66d3!2zSMO8c27DvCBLYXJha2HFnywgMzUzNy4gU2suIE5vOjc4IEQ6YSwgMDczMjAgS2VwZXovQW50YWx5YQ!5e0!3m2!1sen!2str!4v1721242143190!5m2!1sen!2str"
                  className="w-full"
                  width={600}
                  height={450}
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TeklifForm;
