import React from "react";
import Navbar from "../components/Navbar";
import TeklifHero from "../components/Teklif/TeklifHero";
import Hakkimizda from "../components/Teklif/Hakkimizda";
import TeklifForm from "../components/Teklif/TeklifForm";
import Footer from "../components/Footer";

const TeklifAl = () => {
  return (
    <>
      <Navbar />
      <TeklifHero />
      <Hakkimizda />
      <TeklifForm />
      <Footer />
    </>
  );
};

export default TeklifAl;
