import React from "react";
import AyracThree from "./Ayrac/AyracThree";

const Contact = () => {
  return (
    <div>
      <section className="bg-gray-100">
        <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:py-20 lg:px-8">
          <div className="max-w-2xl lg:max-w-4xl mx-auto text-center">
            <h2
              className="text-4xl font-extrabold text-gray-900"
              id="contactUs"
            >
              Konumumuzu Ziyaret Edin
            </h2>
            <p className="mt-3 text-lg text-gray-500">
              Size en iyi şekilde hizmet edelim
            </p>
          </div>
          <div className="mt-8 lg:mt-20">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <div className="max-w-full mx-auto rounded-lg overflow-hidden">
                  <div className="border-t border-gray-200 px-6 py-4">
                    <h3 className="text-lg font-medium text-gray-900">
                      İletişim
                    </h3>
                    <p className="mt-1  text-gray-600">
                      <a href="tel:+123">Telefon: +90 538 456 00 11</a>
                      <br />
                      <a href="tel:+123">Telefon: +90 539 986 95 93</a>
                    </p>
                  </div>
                  <div className="px-6 py-4">
                    <h3 className="text-lg font-medium text-gray-900">
                      Adresimiz
                    </h3>
                    <p className="mt-1 text-gray-600">
                      Hüsnü Karakaş Mah. 3537 Sok. No:78/A Kepez/Antalya
                    </p>
                  </div>
                  <div className="border-t border-gray-200 px-6 py-4">
                    <h3 className="text-lg font-medium text-gray-900">
                      Çalışma Saatlerimiz
                    </h3>
                    <p className="mt-1 text-gray-600">
                      Pazartesi - Cumartesi : 08:00 - 17:00
                    </p>
                  </div>
                </div>
              </div>
              <div className="rounded-lg overflow-hidden order-none sm:order-first">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d670.3972627863237!2d30.728900535653008!3d36.939008880382104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c385940cd7fa6f%3A0x59cb2847d18e66d3!2zSMO8c27DvCBLYXJha2HFnywgMzUzNy4gU2suIE5vOjc4IEQ6YSwgMDczMjAgS2VwZXovQW50YWx5YQ!5e0!3m2!1sen!2str!4v1721242143190!5m2!1sen!2str"
                  className="w-full"
                  width={600}
                  height={450}
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <AyracThree />
    </div>
  );
};

export default Contact;
