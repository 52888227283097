import React from "react";
import { Link } from "react-router-dom";

const HizmetContent = () => {
  return (
    <section className="my-8 sm:my-10 grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-4 p-6">
      <div className="px-2 py-20 w-full flex justify-center">
        <div className="bg-white lg:mx-8 lg:flex lg:max-w-5xl lg:shadow-lg rounded-lg">
          <div className="lg:w-1/2">
            <div
              className="lg:scale-110 h-80 bg-cover lg:h-full rounded-b-none border lg:rounded-lg"
              style={{
                backgroundImage:
                  'url("https://www.dogalgazsistemleri.com/wp-content/uploads/2022/09/yerden-isitma.jpg")',
              }}
            ></div>
          </div>
          <div className="py-12 px-6 lg:px-12 max-w-xl lg:max-w-5xl lg:w-1/2 rounded-t-none border lg:rounded-lg">
            <h2 className="text-3xl text-gray-800 font-bold">
              Yerden
              <span className="text-indigo-600">Isıtma</span>
            </h2>
            <p className="mt-4 text-gray-600">
              Yerden ısıtma döşeme zemininin altına yerleştirilen boruların
              içerisinden sıcak suyun dolaştırılması sayesinde gerçekleşir. Bu
              sistem kullanıcılarına konfor, enerji verimliliği ve güvenlik gibi
              avantajlar sağlar. Yerden ısıtma sistemlerinde de kazan, kombi,
              ısı pompası gibi bir ısı kaynağına ihtiyaç duyulur. Dolayısıyla
              yerden ısıtma sistemi birbirine bağlı farklı sistemlerin bir araya
              getiririlmesiyle oluştuğundan uzmanlık gerektiren işlerden
              biridir.
            </p>
            <div className="mt-8">
              <Link to="/teklifal">
                <span className="bg-gray-900 text-gray-100 px-5 py-3 font-semibold rounded">
                  Teklif Al
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="px-2 py-20 w-full flex justify-center">
        <div className="bg-white lg:mx-8 lg:flex lg:max-w-5xl lg:shadow-lg rounded-lg">
          <div className="lg:w-1/2">
            <div
              className="lg:scale-110 h-80 bg-cover lg:h-full rounded-b-none border lg:rounded-lg"
              style={{
                backgroundImage:
                  'url("https://www.dogalgazsistemleri.com/wp-content/uploads/2023/02/multisystem.webp")',
              }}
            ></div>
          </div>
          <div className="py-12 px-6 lg:px-12 max-w-xl lg:max-w-5xl lg:w-1/2 rounded-t-none border lg:rounded-lg">
            <h2 className="text-3xl text-gray-800 font-bold">
              Vrf Klima
              <span className="text-indigo-600">Sistemleri</span>
            </h2>
            <p className="mt-4 text-gray-600">
              VRF Sistemler; bir veya birden fazla dış ünite ile birçok iç
              ünitenin bulunduğu büyük sistemlerdir. Bu tip sistemler,
              şartlandırılacak mahal sayısının çok fazla olduğu yapılarda
              kullanılmaktadır.
            </p>
            <div className="mt-8">
              <Link to="/teklifal">
                <span className="bg-gray-900 text-gray-100 px-5 py-3 font-semibold rounded">
                  Teklif Al
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="px-2 py-20 w-full flex justify-center">
        <div className="bg-white lg:mx-8 lg:flex lg:max-w-5xl lg:shadow-lg rounded-lg">
          <div className="lg:w-1/2">
            <div
              className="lg:scale-110 h-80 bg-cover lg:h-full rounded-b-none border lg:rounded-lg"
              style={{
                backgroundImage:
                  'url("https://www.dogalgazsistemleri.com/wp-content/uploads/2022/09/dogalgaz-tesisati-1-1.jpg")',
              }}
            ></div>
          </div>
          <div className="py-12 px-6 lg:px-12 max-w-xl lg:max-w-5xl lg:w-1/2 rounded-t-none border lg:rounded-lg">
            <h2 className="text-3xl text-gray-800 font-bold">
              Doğalgaz
              <span className="text-indigo-600">Tesisatı</span>
            </h2>
            <p className="mt-4 text-gray-600">
              Doğalgaz evlerde ve işyerlerinde ısınma, mutfak ve sıcak su amaçlı
              olarak yaygın olarak kullanılmaktadır. Doğalgazı kullanabilmek
              için öncelikle tesisatının döşenmesi gerekmektedir.Bunun için
              mutlaka işinde uzman ve tecrübeli firmalar ile çalışmalı ve
              döşenecek tesisatın projeye uygun olması gerekmektedir.
            </p>
            <div className="mt-8">
              <Link to="/teklifal">
                <span className="bg-gray-900 text-gray-100 px-5 py-3 font-semibold rounded">
                  Teklif Al
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="px-2 py-20 w-full flex justify-center">
        <div className="bg-white lg:mx-8 lg:flex lg:max-w-5xl lg:shadow-lg rounded-lg">
          <div className="lg:w-1/2">
            <div
              className="lg:scale-110 h-80 bg-cover lg:h-full rounded-b-none border lg:rounded-lg"
              style={{
                backgroundImage:
                  'url("https://www.dogalgazsistemleri.com/wp-content/uploads/2023/02/isi-pompasi.jpg")',
              }}
            ></div>
          </div>
          <div className="py-12 px-6 lg:px-12 max-w-xl lg:max-w-5xl lg:w-1/2 rounded-t-none border lg:rounded-lg">
            <h2 className="text-3xl text-gray-800 font-bold">
              Isı
              <span className="text-indigo-600">Pompası</span>
            </h2>
            <p className="mt-4 text-gray-600">
              Isı pompaları, geleceğin iklimlendirme teknolojisi ürünlerinden
              biri kabul edilmektedir. Isı pompaları dünya üzerinde var olan
              enerjiyi transfer ederek ısı ürettiklerinden fosil yakıtlara
              nazaran daha temiz enerji üretmektedir. Bu nedenle küresel
              ısınmayla mücadelede etkili bir ısınma sunmaktadır.
            </p>
            <div className="mt-8">
              <Link to="/teklifal">
                <span className="bg-gray-900 text-gray-100 px-5 py-3 font-semibold rounded">
                  Teklif Al
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="px-2 py-20 w-full flex justify-center">
        <div className="bg-white lg:mx-8 lg:flex lg:max-w-5xl lg:shadow-lg rounded-lg">
          <div className="lg:w-1/2">
            <div
              className="lg:scale-110 h-80 bg-cover lg:h-full rounded-b-none border lg:rounded-lg"
              style={{
                backgroundImage:
                  'url("https://kazankaskad.com/storage/2016/01/buderus-kazan.png")',
              }}
            ></div>
          </div>
          <div className="py-12 px-6 lg:px-12 max-w-xl lg:max-w-5xl lg:w-1/2 rounded-t-none border lg:rounded-lg">
            <h2 className="text-3xl text-gray-800 font-bold">
              Kaskad Kazan
              <span className="text-indigo-600">Sistemleri</span>
            </h2>
            <p className="mt-4 text-gray-600">
              Kaskad sistem, birden fazla kazanın kontrol modülü aracılığı ile
              senkronize olarak çalıştığı ısıtma sistemidir. Bu sistemde genel
              prensip, kazanların hidrolik olarak aynı sistem içerisinde yer
              almasıdır. Bireysel ısıtma sistemlerine göre daha tasarruflu olan
              kaskad ısıtma sistemleri, konutların ısıtılmasında veya otel,
              yurt, spor salonu, ofis ve benzeri ticari binaların ısıtılmasında
              kullanılır.
            </p>
            <div className="mt-8">
              <Link to="/teklifal">
                <span className="bg-gray-900 text-gray-100 px-5 py-3 font-semibold rounded">
                  Teklif Al
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="px-2 py-20 w-full flex justify-center">
        <div className="bg-white lg:mx-8 lg:flex lg:max-w-5xl lg:shadow-lg rounded-lg">
          <div className="lg:w-1/2">
            <div
              className="lg:scale-110 h-80 bg-cover lg:h-full rounded-b-none border lg:rounded-lg"
              style={{
                backgroundImage:
                  'url("https://static.ticimax.cloud/cdn-cgi/image/width=-,quality=85/58119/uploads/urunresimleri/buyuk/gufo-eko-l14-26-kw-seramik-radyant-isi-08f965.jpg")',
              }}
            ></div>
          </div>
          <div className="py-12 px-6 lg:px-12 max-w-xl lg:max-w-5xl lg:w-1/2 rounded-t-none border lg:rounded-lg">
            <h2 className="text-3xl text-gray-800 font-bold">
              Radyant Isı
              <span className="text-indigo-600">Sistemleri</span>
            </h2>
            <p className="mt-4 text-gray-600">
              Radyant ısıtma sistemleri konusunda insanlar için esin kaynağı
              yine doğadır. Kış aylarında bulutlu bir havada dolaştığınızı ve
              bir anda güneşin bulutların arasından çıktığını düşünün, nasıl
              tatlı bir sıcaklık hissedersiniz. Hissedilen bu ısınmanın nedeni
              bir anda 5- 10 °C hava sıcaklığı artışı değil, size ulaşmaya
              başlayan Kızılötesi ışınlardır.
            </p>
            <div className="mt-8">
              <Link to="/teklifal">
                <span className="bg-gray-900 text-gray-100 px-5 py-3 font-semibold rounded">
                  Teklif Al
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="px-2 py-20 w-full flex justify-center">
        <div className="bg-white lg:mx-8 lg:flex lg:max-w-5xl lg:shadow-lg rounded-lg">
          <div className="lg:w-1/2">
            <div
              className="lg:scale-110 h-80 bg-cover lg:h-full rounded-b-none border lg:rounded-lg"
              style={{
                backgroundImage:
                  'url("https://www.dogalgazsistemleri.com/wp-content/uploads/2022/09/dogalgaz-tesisati-e1664434594751.jpg")',
              }}
            ></div>
          </div>
          <div className="py-12 px-6 lg:px-12 max-w-xl lg:max-w-5xl lg:w-1/2 rounded-t-none border lg:rounded-lg">
            <h2 className="text-3xl text-gray-800 font-bold">
              Kombi
              <span className="text-indigo-600">Radyatör</span>
            </h2>
            <p className="mt-4 text-gray-600">
              Kombiler; ev, işyeri, ofis, gibi pek çok farklı mekânda
              kullanılan, doğalgaz kullanımını ve sıcak su ihtiyacını gideren
              cihazlardır. Eviniz için kombi seçerken ısınma ve güvenlik
              özelliklerini, evin boyutlarını ve şeklini göz önünde
              bulundurmalısınız. Evinize en uygun kombiyi bulmak için işinde
              uzman bir ekipten destek almanız oldukça önemli. Uzman bir ekip
              sizi gereksiz maliyet ve zaman kaybından kurtarır.
            </p>
            <div className="mt-8">
              <Link to="/teklifal">
                <span className="bg-gray-900 text-gray-100 px-5 py-3 font-semibold rounded">
                  Teklif Al
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="px-2 py-20 w-full flex justify-center">
        <div className="bg-white lg:mx-8 lg:flex lg:max-w-5xl lg:shadow-lg rounded-lg">
          <div className="lg:w-1/2">
            <div
              className="lg:scale-110 h-80 bg-cover lg:h-full rounded-b-none border lg:rounded-lg"
              style={{
                backgroundImage:
                  'url("https://ideacdn.net/idea/bx/35/myassets/products/859/fhs-9000-mfc.jpg?revision=1715080942")',
              }}
            ></div>
          </div>
          <div className="py-12 px-6 lg:px-12 max-w-xl lg:max-w-5xl lg:w-1/2 rounded-t-none border lg:rounded-lg">
            <h2 className="text-3xl text-gray-800 font-bold">
              Doğalgaz
              <span className="text-indigo-600">Sobası</span>
            </h2>
            <p className="mt-4 text-gray-600">
              Doğalgaz sobası, tesisat üzerinden gelen gazı krom alaşım
              özeliğine sahip brolür desteğiyle sıkıştıran ve bulunduğu bölgenin
              ısınmasını sağlayan ısıtıcı türüdür. İki farklı türü bulunur.
              Birincisi, bacalı ikincisi ise hermetik doğalgaz sobasıdır.
              Günümüzde bacalı doğalgaz sobaları yasak listesinde… Bu yüzden gaz
              dağıtım firmaları hermetik doğalgaz sobalarını kullanıcılara
              tavsiye ederken, bacalı doğalgaz sobalarının bulunduğu evlere ise
              gaz hizmetini kesmektedir.
            </p>
            <div className="mt-8">
              <Link to="/teklifal">
                <span className="bg-gray-900 text-gray-100 px-5 py-3 font-semibold rounded">
                  Teklif Al
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HizmetContent;
