import React from "react";
import Text from "./Text";

const HizmetHero = () => {
  return (
    <>
      <div
        style={{
          background:
            "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(https://www.akkayamuhendislik.com.tr/upload/product/1616359251.jpg) no-repeat center",
          backgroundSize: "cover",
        }}
        className="py-64 px-1 md:px-8 text-center relative text-white font-bold text-2xl md:text-3xl overflow-auto"
      >
        <Text />
      </div>
    </>
  );
};

export default HizmetHero;
