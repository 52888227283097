import React from "react";
import Modal from "../Modal/Modal";

const BilgibankContent = () => {
  return (
    <div className="m-10">
      <>
        {/* inspired by tailwindcss.com */}
        <ul className="grid grid-cols-1 xl:grid-cols-3 gap-y-10 gap-x-6 items-start p-8">
          <li className="relative flex flex-col sm:flex-row xl:flex-col items-start">
            <div className="order-1 sm:ml-6 xl:ml-0">
              <h3 className="mb-1 text-slate-900 font-semibold">
                <span className="mb-1 block text-sm leading-6 text-indigo-500">
                  Kombi
                </span>
                Kombi Bakımı Ne Zaman Yapılır?
              </h3>
              <div className="prose prose-slate prose-sm text-slate-600">
                <p>
                  Doğalgazlı evde yaşayan bireylerin en merak ettiği soruların
                  başında “kombi bakımı ne zaman yapılır?” gelir. Çünkü, kombi
                  cihazları ısınma, sıcak su ve mutfak ihtiyaçlarımızı gideren
                  son derece kullanışlı bir teknoloji. Bu yüzden, kombi
                  bakımının aksatılmadan, periyodik olarak yapılması gerekir.
                  Kombi Bakımı Ne Sıklıkla Yaptırılmalıdır? Kombi…
                </p>
                <Modal
                  firsth="Kombi Bakımı Ne Zaman Yapılır?"
                  firstp="Doğalgazlı evde yaşayan bireylerin en merak ettiği soruların başında “kombi bakımı ne zaman yapılır?” gelir. Çünkü, kombi cihazları ısınma, sıcak su ve mutfak ihtiyaçlarımızı gideren son derece kullanışlı bir teknoloji. Bu yüzden, kombi bakımının aksatılmadan, periyodik olarak yapılması gerekir."
                  secondh="Kombi Bakımı Ne Sıklıkla Yaptırılmalıdır?"
                  secondp="Kombi bakımı ne zaman yapılır? sorusunun net bir cevabı yok. Ancak, alanında uzman kişilerin belirttiği uygulama biçimine göre her kış mevsimi öncesinde genel bir kombi bakımı yapılması gerekir.

Tabi sadece kış mevsimi öncesinde değil aynı zamanda yaz mevsiminin başlangıcında da bakım yaptırmanız mümkün. Ancak yılda 1 kez bakım yaptırmak şart."
                  thirdh="Her Yıl Kombi Bakımı Yapılmazsa Ne Olur?"
                  thirdp="Örneğin; kış mevsimi öncesinde bakım yaptırmadınız ve soğuk havalara bakımsız kombi ile girdiniz. Havanın en soğuk günlerinden birinde kombinizin arıza yaptığını düşünün. Sezon içerisinde sizin gibi birçok kişi aynı durumdan şikayetçi ve servis ya da ustaların çalışma saatleri yoğun.

Bir nevi, kışın ortasında ısınma ve ısıtmadan mahrum kalacaksınız. Yoğun sezon içerisinde hem ustayı zor bulacaksınız hem de daha yüksek maliyetle karşı karşıya kalacaksınız. İşte, hem konforunuzdan olmamak hem de yüksek ücret ödememek için kombi bakımının her sene periyodik olarak yapılması gerekir.

Kombi bakımında dikkat edilmesi gereken bazı hususlar vardır. Bu hususların en önemlisi belki de temizleme ve bakım işlemlerinin tümünün profesyonel bir uzman tarafından yapılması. Aksi takdirde bu işlemlerden randıman almak pekte mümkün değildir. Sektörde 20 yıldan fazla süredir hizmet veren Üçay Mühendislik olarak her türlü kombi, radyatör ve doğal"
                  forthh="Periyodik Kombi Bakımı Nedir?"
                  forthp="Periyodik kombi bakımı belirli aralıklarda ürünün ihtiyacına göre uzman kişilerin kontrolünden geçmesi ve problemin giderilmesidir. Kombi tesisat bakımı da bu işlemlerin içinde yer almaktadır. Çünkü kombinizin çalışmasını ve verimliliğini tesisatın ve cihazın parçalarının durumu doğrudan etkilemektedir."
                />
              </div>
            </div>
            <img
              src="images/1.png"
              alt=""
              className="mb-6 shadow-md rounded-lg bg-slate-50 w-full sm:w-[17rem] sm:mb-0 xl:mb-6 xl:w-full"
              width={1216}
              height={640}
            />
          </li>
          <li className="relative flex flex-col sm:flex-row xl:flex-col items-start">
            <div className="order-1 sm:ml-6 xl:ml-0">
              <h3 className="mb-1 text-slate-900 font-semibold">
                <span className="mb-1 block text-sm leading-6 text-purple-500">
                  Oda Termostatı
                </span>
                Oda Termostatı Nedir, Ne İşe Yarar? | Tasarruf Sağlar mı?
              </h3>
              <div className="prose prose-slate prose-sm text-slate-600">
                <p>
                  Oda termostatı; kombi cihazlarıyla birlikte çalışan gereksiz
                  ısınmanın önüne geçen ve yakıt tasarrufu sağlayan cihazdır.
                  Kullanımı ve kurulumu son derece basit olan bu cihazlar
                  radyatörlere (petek) sabitlenir. Radyatör ısı seviyesini
                  belirlenen ısı sıcaklığına geldiğinde cihazın yapısı bu
                  uyarıyı alır ve ısıyı sabitlemeye çalışır. Yukarıda sizler…
                </p>
                <Modal
                  firsth="Oda Termostatı Nedir, Ne İşe Yarar? | Tasarruf Sağlar mı?"
                  firstp="Oda termostatı; kombi cihazlarıyla birlikte çalışan gereksiz ısınmanın önüne geçen ve yakıt tasarrufu sağlayan cihazdır. Kullanımı ve kurulumu son derece basit olan bu cihazlar radyatörlere (petek) sabitlenir. Radyatör ısı seviyesini belirlenen ısı sıcaklığına geldiğinde cihazın yapısı bu uyarıyı alır ve ısıyı sabitlemeye çalışır.

Yukarıda sizler için sistem hakkında kısa bir bilgilendirme yaptık. Yazımızın devamında ise kullanımı, ne işe yaradığı, çalışma prensibi ve avantajları – dezavantajları hakkında detaylara değineceğiz."
                  secondh="Oda Termostatı Ne İşe Yarar?"
                  secondp="Evlerde kullanılan kombi ve peteklerde ısı derecesi 20°C. Derece 1 seviye yükseldiğinde %10’a yakın ısı artışı meydana gelir. Bu nedenle, doğalgaz faturaları da aynı oranda yükselir. Bu durumu engellemenin en basit yollarından bir tanesi oda termostatları…

Oda sıcaklığını net bir şekilde algılayan termostatlar sayesinde kullanıcılar ortalama %20 enerji tasarrufu sağlar. Ek olarak ısı belirlenen seviyeye geldiğinde kombi açma kapama işlemini kendi kendine halleder. Sonuç olarak bütün işlem otomatik olarak cihaz tarafından uygulanır.

Gereksiz kombi kullanımının önüne geçtiği için de cihazın yıpranmasını engeller. Bu sebeple daha uzun ömürlü bir cihaz kullanımı sağlar."
                />
              </div>
            </div>
            <img
              src="images/3.png"
              alt=""
              className="mb-6 shadow-md rounded-lg bg-slate-50 w-full sm:w-[17rem] sm:mb-0 xl:mb-6 xl:w-full"
              width={1216}
              height={640}
            />
          </li>
          <li className="relative flex flex-col sm:flex-row xl:flex-col items-start">
            <div className="order-1 sm:ml-6 xl:ml-0">
              <h3 className="mb-1 text-slate-900 font-semibold">
                <span className="mb-1 block text-sm leading-6 text-cyan-500">
                  Kombi
                </span>
                Kombi Bakımı Nasıl Yapılır?
              </h3>
              <div className="prose prose-slate prose-sm text-slate-600">
                <p>
                  Kombi bakımı, doğalgazlı ısıtma sistemleri içerisinde en çok
                  merak edilen ve araştırılan bir konu. Bugün doğalgazın
                  kullanıldığı hemen her evde kombi ile ilgili zaman zaman
                  problemler oluşabiliyor. Bunun nedeni ise gerekli bakımların
                  zamanında yapılmaması. Peki, evde kombi bakımı ile ilgili
                  öğrenilmesi gereken püf noktalar neler?…
                </p>
                <Modal
                  firsth="Kombi Bakımı Nasıl Yapılır?"
                  firstp="Kombi bakımı, doğalgazlı ısıtma sistemleri içerisinde en çok merak edilen ve araştırılan bir konu. Bugün doğalgazın kullanıldığı hemen her evde kombi ile ilgili zaman zaman problemler oluşabiliyor. Bunun nedeni ise gerekli bakımların zamanında yapılmaması.

Peki, evde kombi bakımı ile ilgili öğrenilmesi gereken püf noktalar neler? Kombilerin yıllık bakımı yapılmazsa ne gibi olumsuzluk ortaya çıkar? İşte, kombi bakımı hakkında bilinmesi gereken tüm detaylar…"
                  secondh="Kombinin Bakımı ve Yapılması Gerekenler"
                  secondp="Hemen her alanda olduğu gibi kombilerin de belli periyotlarda bakımlarının yapılması gerekiyor. Çünkü, bakımı yapılmayan kombi belli bir kullanımdan sonra arızlar ortaya çıkartıp, hem ısınma ve ısıtma özelliğini kaybediyor hem de insanlara yüksek maliyetlere sebep olabiliyor.

Özellikle kış mevsimini sağlıklı bir şekilde geçirmek istiyorsanız yazın sonlarına doğru kombinizin bakımını yaptırmanızı tavsiye ediyoruz. Bu yüzden kombinin bakımı hayati derece de öneme sahip."
                />
              </div>
            </div>
            <img
              src="images/2.png"
              alt=""
              className="mb-6 shadow-md rounded-lg bg-slate-50 w-full sm:w-[17rem] sm:mb-0 xl:mb-6 xl:w-full"
              width={1216}
              height={640}
            />
          </li>
          <li className="relative flex flex-col sm:flex-row xl:flex-col items-start">
            <div className="order-1 sm:ml-6 xl:ml-0">
              <h3 className="mb-1 text-slate-900 font-semibold">
                <span className="mb-1 block text-sm leading-6 text-indigo-500">
                  Kombi
                </span>
                Kombi Evin Neresinde Olmalı? Balkon mu Mutfak mı?
              </h3>
              <div className="prose prose-slate prose-sm text-slate-600">
                <p>
                  Doğalgazlı evlerde kullanıcıların en çok merak ettiği
                  konuların başında “kombi evin neresinde olmalı?” sorusu gelir.
                  Çünkü, bazı evlerde mutfakta ocağa yakın, bazılarında ise
                  balkonda kullanıma uygun. Peki, mutfakta mı yoksa balkonda mı
                  olması gerekir? Kombi Evin Hangi Bölümüne Monte Edilir?
                  Doğalgazlı ısıtma sistemleri içerisinde hem…
                </p>
                <Modal
                  firsth="Kombi Evin Neresinde Olmalı? Balkon mu Mutfak mı?"
                  firstp="Doğalgazlı evlerde kullanıcıların en çok merak ettiği konuların başında “kombi evin neresinde olmalı?” sorusu gelir. Çünkü, bazı evlerde mutfakta ocağa yakın, bazılarında ise balkonda kullanıma uygun. Peki, mutfakta mı yoksa balkonda mı olması gerekir?"
                  secondh="Kombi Evin Hangi Bölümüne Monte Edilir?"
                  secondp="Doğalgazlı ısıtma sistemleri içerisinde hem verimli ısınma hem de enerjide tasarruf sağlayan kombilerin, evin hangi bölüme kullanılması son derece önemli. Evin içerisine kombi kurmak isteyenlerin ilk tercih ettiği bölümler ise mutfak ve balkon alanı…

“Kombinin balkona mı yoksa mutfağa mı koyulması gerekir?” sorusunun sıklıkla sorulmasının nedeni en çok tercih edilen alanlar olması. Ancak bunun net bir cevabı yok. Kullanıma uygun alan olduğu sürece ister balkona isterseniz de mutfağa koyabilirsiniz.

Burada dikkat etmeniz gereken en önemli husus başta evinizin yapısı, sonrasında ise coğrafi – iklimsel faktörler. Bu yüzden, “kombi evin neresinde olmalı?” sorusuna cevap vermenden önce uzman ve yetkili kişilerden fikir alışverişi yapılması gerekir?"
                />
              </div>
            </div>
            <img
              src="images/4.png"
              alt=""
              className="mb-6 shadow-md rounded-lg bg-slate-50 w-full sm:w-[17rem] sm:mb-0 xl:mb-6 xl:w-full"
              width={1216}
              height={640}
            />
          </li>
          <li className="relative flex flex-col sm:flex-row xl:flex-col items-start">
            <div className="order-1 sm:ml-6 xl:ml-0">
              <h3 className="mb-1 text-slate-900 font-semibold">
                <span className="mb-1 block text-sm leading-6 text-purple-500">
                  Doğalgaz Kaçağı
                </span>
                Doğalgaz Kaçağı Nasıl Anlaşılır?
              </h3>
              <div className="prose prose-slate prose-sm text-slate-600">
                <p>
                  Doğalgaz kaçağı, özellikle doğalgaz tesisatı ya da kombi
                  tesisatının yönetmeliğe göre döşenmediği durumlarda ortaya
                  çıkar. Hayati derecede öneme sahip olan bu gaz kaçağına karşı
                  belli dönemlerde kontrollerin sağlanması gerekmektedir. Çünkü,
                  bu soruna karşı bir önlem alınmazsa hem mal hem de can
                  kayıpları ortaya çıkacaktır. Peki,…
                </p>
                <Modal
                  firsth="Doğalgaz Kaçağı Nasıl Anlaşılır?"
                  firstp="Doğalgaz kaçağı, özellikle doğalgaz tesisatı ya da kombi tesisatının yönetmeliğe göre döşenmediği durumlarda ortaya çıkar. Hayati derecede öneme sahip olan bu gaz kaçağına karşı belli dönemlerde kontrollerin sağlanması gerekmektedir. Çünkü, bu soruna karşı bir önlem alınmazsa hem mal hem de can kayıpları ortaya çıkacaktır.

Peki, doğalgaz kaçağı nasıl anlaşılır? Evde gaz kaçağı fark edilirse neler yapılması gerekir? Gaz kaçağının kontrolü için hangi numarayı arayabiliriz? Hazırsanız başlayalım…"
                  secondh="Evde Doğalgaz Kaçağının Belirtileri"
                  secondp="Doğalgaz kaçağında sızıntının anlaşılması normal şartlar altında zordur. Bunun nedeni, doğalgazın kokusuz bir yapıya sahip bir gaz türü olmasıdır. Ancak, bunu ortadan kaldırmak için ev tipi kullanımlarda doğalgazın içerisine özel bir konu eklenmiştir."
                />
              </div>
            </div>
            <img
              src="images/5.png"
              alt=""
              className="mb-6 shadow-md rounded-lg bg-slate-50 w-full sm:w-[17rem] sm:mb-0 xl:mb-6 xl:w-full"
              width={1216}
              height={640}
            />
          </li>
          <li className="relative flex flex-col sm:flex-row xl:flex-col items-start">
            <div className="order-1 sm:ml-6 xl:ml-0">
              <h3 className="mb-1 text-slate-900 font-semibold">
                <span className="mb-1 block text-sm leading-6 text-cyan-500">
                  Yerden Isıtma
                </span>
                Sonradan Yerden Isıtma Sistemi Yapılır mı?
              </h3>
              <div className="prose prose-slate prose-sm text-slate-600">
                <p>
                  Evet, sonradan yerden ısıtma sistemi yaptırmak mümkündür.
                  Sonradan yapılacak yerden ısıtma sisteminin maliyeti genel
                  olarak ilkinden daha pahalı olmaktadır. Sonradan yerden ısıtma
                  sistemini yapabilmek için betonun kırılması gerekir. Bilinmesi
                  gereken bir diğer nokta da kablolu sistemin yeni inşaatlarda
                  ya da tadilat halindeki yapılarda yapılmasının mümkün…
                </p>
                <Modal
                  firsth="Sonradan Yerden Isıtma Sistemi Yapılır mı?"
                  firstp="Evet, sonradan yerden ısıtma sistemi yaptırmak mümkündür. Sonradan yapılacak yerden ısıtma sisteminin maliyeti genel olarak ilkinden daha pahalı olmaktadır. Sonradan yerden ısıtma sistemini yapabilmek için betonun kırılması gerekir. Bilinmesi gereken bir diğer nokta da kablolu sistemin yeni inşaatlarda ya da tadilat halindeki yapılarda yapılmasının mümkün olduğudur. Sonradan yerden ısıtma sistemi yapılması mümkün olsa da her yöntemi ile kurulum yapmak mümkün olmuyor. Yerden ısıtma sistemlerinde zemin altına yapılan boru döşemeleri sayesinde su dolaşımı ile zeminin de ısınması sağlanıyor. Bu ısıtma yöntemi konutlardan iş yerlerine, hastane ve okullara pek çok alanda tercih ediliyor."
                  secondh="Yerden Isıtma Nedir?"
                  secondp="Yerden ısıtma odanın ya da konuttaki belirli alanın ısıtmasının yerden döşenen borular ile sağlandığı sistemlerdir. Yerden ısıtma sistemleri sayesinde ısı alana eşit olarak dağılır ve alanın tamamı aynı sıcaklık seviyesine gelir. Yerden ısıtma sistemlerinde boruların yanı sıra kablolar da kullanılabilmektedir. Ayrıca farklı bir kaynaktan ısıtılan suyun yere döşenmiş borularda dolaşması ile de yerden ısıtma sağlanabilmektedir. Yerden ısıtmalı tesisatlar konutlarda genellikle güvenli ve düşük maliyetli olmaları nedeniyle tercih edilmektedir. Ayrıca sistemin ısıyı eşit dağıtması da olumlu bir etkendir. Yerden ısıtma stadyum ve hamam gibi çok daha geniş alanların ısınmasını sağlamak için de kullanılmaktadır. Bu gibi alanlarda yerden yükselen ısının tüm alanı eşit sıcaklığa getirmesi oldukça avantajlı bir durumdur."
                />
              </div>
            </div>
            <img
              src="images/6.png"
              alt=""
              className="mb-6 shadow-md rounded-lg bg-slate-50 w-full sm:w-[17rem] sm:mb-0 xl:mb-6 xl:w-full"
              width={1216}
              height={640}
            />
          </li>
          <li className="relative flex flex-col sm:flex-row xl:flex-col items-start">
            <div className="order-1 sm:ml-6 xl:ml-0">
              <h3 className="mb-1 text-slate-900 font-semibold">
                <span className="mb-1 block text-sm leading-6 text-indigo-500">
                  Klima
                </span>
                Neden Klima Bakımı Yapmalıyım?
              </h3>
              <div className="prose prose-slate prose-sm text-slate-600">
                <p>
                  Klima bakımı sağlık açısından oldukça önemlidir.
                  Hastalıklardan kaçınmak ve bir sağlık problemi yaşamamak için
                  klima bakımlarının yapılması gerekir. Klima bakımı
                  yapılmadığında arınmamış, kötü kalitede bir hava solunur.
                  Bakteri, polen gibi maddelerden arınmış kaliteli bir havanın
                  solunması için klima bakımının düzenli periyotlarla yapılması
                  gerekir. Sağlıklı ve…
                </p>
                <Modal
                  firsth="Neden Klima Bakımı Yapmalıyım?"
                  firstp="Klima bakımı sağlık açısından oldukça önemlidir. Hastalıklardan kaçınmak ve bir sağlık problemi yaşamamak için klima bakımlarının yapılması gerekir. Klima bakımı yapılmadığında arınmamış, kötü kalitede bir hava solunur. Bakteri, polen gibi maddelerden arınmış kaliteli bir havanın solunması için klima bakımının düzenli periyotlarla yapılması gerekir. Sağlıklı ve güvenli bir kullanım için klima bakımı şarttır. Ayrıca klima bakımı ile klimanın uzun ömürlü olarak kullanılması mümkün olur. Düzenli bakımı yapılmayan klimalar daha kolay arızalanarak bozulabilir."
                  secondh="Klima Bakımının Önemi"
                  secondp="Klima bakımı klimanın sağlıklı, verimli ve uzun ömürlü kullanımı için oldukça gereklidir. Bakımı yapılmayan klimaların çalışmasında aksaklıklar, verimsizlikler başlar. Uzun vadede bakımı yapılmayan ya da eksik yapılan klimalar bozulur. Klima bakımı yapılması ile enerji tasarrufu da sağlanmış olur. Bakımı düzenli ve doğru yapılan klimalar faturalara da olumlu yansır. Ayrıca hijyen açısından da klimanın bakımının yapılması gereklidir. Aksi takdirde klima filtrelerinde birikenler ortamın havasının kalitesini düşürerek çeşitli hastalıklara sebebiyet verebilir."
                />
              </div>
            </div>
            <img
              src="images/7.png"
              alt=""
              className="mb-6 shadow-md rounded-lg bg-slate-50 w-full sm:w-[17rem] sm:mb-0 xl:mb-6 xl:w-full"
              width={1216}
              height={640}
            />
          </li>
          <li className="relative flex flex-col sm:flex-row xl:flex-col items-start">
            <div className="order-1 sm:ml-6 xl:ml-0">
              <h3 className="mb-1 text-slate-900 font-semibold">
                <span className="mb-1 block text-sm leading-6 text-purple-500">
                  Vrf
                </span>
                VRF Nedir, Nasıl Çalışır?
              </h3>
              <div className="prose prose-slate prose-sm text-slate-600">
                <p>
                  VRF NEDİR? VRF klima sistemi, değişken akışkan debili
                  sistemlerdir. Yani sistem, içinde tuttuğu akışkanın debisini
                  değiştirerek gereken kapasiteyi tam ve net olarak
                  ayarlayabilir. Son zamanlarda VRF klima sistemlerinde, hem
                  çevre dostu olan hem de teknik özellikleri ile de son derece
                  verimli R410A soğutucu akışkan kullanılır….
                </p>
                <Modal
                  firsth="VRF NEDİR?"
                  firstp="VRF klima sistemi, değişken akışkan debili sistemlerdir. Yani sistem, içinde tuttuğu akışkanın debisini değiştirerek gereken kapasiteyi tam ve net olarak ayarlayabilir.

Son zamanlarda VRF klima sistemlerinde, hem çevre dostu olan hem de teknik özellikleri ile de son derece verimli R410A soğutucu akışkan kullanılır. Bu sistem, pek çok iç ünitenin tek bir bakır boru hattı ile aynı dış üniteden beslenmesi nedeni ile mimari ve uygulama istekleri ile ilgili çok büyük fırsatlar sağlar."
                  secondh="VRF Sistemleri Nasıl Çalışır?"
                  secondp="VRF klima sisteminin çalışma işleyişi sistem içinde bulunur ve her VRF klima sistemi üreticisi tarafından da tescillenir. Sistem, verileri kullanıcıdan ve doğadan alır. Ve bu veriler sayesinde maksimum güç tüketimini uygulayarak ortamın istenilen rahatlık koşullarına ulaşmasını sağlar. Bir kullanıcı tarafından iç ünitelerden biri açık hale getirilince, dış ünite buradan aldığı sinyal ile çalışmaya başlar. Bu durumda, dış ortam şartlarını, iç ortam ihtiyaçlarını inceler ve kompresörü istenilen seviyede çalıştırır.

Başka bir iç ünite açıldığında ise dış ünite çalışan iç ünitelerdeki ihtiyaçları tekrar hesaplar ve istene şartları sağlamak adına kompresörün gücünü arttırır. Yani bu sisten tamimiyle otomatik çalışır. İç üniteden gelen isteğe göre ve dış ortam şartlarına bağlı olarak güç tüketimini iyileştirir."
                />
              </div>
            </div>
            <img
              src="images/8.png"
              alt=""
              className="mb-6 shadow-md rounded-lg bg-slate-50 w-full sm:w-[17rem] sm:mb-0 xl:mb-6 xl:w-full"
              width={1216}
              height={640}
            />
          </li>
          <li className="relative flex flex-col sm:flex-row xl:flex-col items-start">
            <div className="order-1 sm:ml-6 xl:ml-0">
              <h3 className="mb-1 text-slate-900 font-semibold">
                <span className="mb-1 block text-sm leading-6 text-cyan-500">
                  Radyatör
                </span>
                Radyatör Montajı Nedir?
              </h3>
              <div className="prose prose-slate prose-sm text-slate-600">
                <p>
                  Radyatör montajı, ısıtıcıların verimli olarak çalışmasını,
                  sistemdeki enerji kaybının önlenmesini ve ısı kayıplarındaki
                  değerlerin doğruluğunu sağlamak amacıyla yapılan bir işlemdir.
                  Geçmişten bu yana farklı ısıtıcılar üretilmiştir. Bu
                  ısıtıcılar üretilen malzemeye göre döküm, alüminyum, çelik
                  olarak ayrılmasının yanı sıra; kanatlı, borulu, dilimli, sıcak
                  hava apareyleri, levha…
                </p>
                <Modal
                  firsth="Radyatör Montajı Nedir?"
                  firstp="Radyatör montajı,  ısıtıcıların verimli olarak çalışmasını, sistemdeki enerji kaybının önlenmesini ve ısı kayıplarındaki değerlerin doğruluğunu sağlamak amacıyla yapılan bir işlemdir. Geçmişten bu yana farklı ısıtıcılar üretilmiştir. Bu ısıtıcılar üretilen malzemeye göre döküm, alüminyum, çelik olarak ayrılmasının yanı sıra; kanatlı, borulu, dilimli, sıcak hava apareyleri, levha olarak da adlandırılabilmektedir. Fakat bunlardan en çok kullanılanı panel radyatörler ve dökme dilimli ısıtıcılardır."
                />
              </div>
            </div>
            <img
              src="images/9.png"
              alt=""
              className="mb-6 shadow-md rounded-lg bg-slate-50 w-full sm:w-[17rem] sm:mb-0 xl:mb-6 xl:w-full"
              width={1216}
              height={640}
            />
          </li>
          <li className="relative flex flex-col sm:flex-row xl:flex-col items-start">
            <div className="order-1 sm:ml-6 xl:ml-0">
              <h3 className="mb-1 text-slate-900 font-semibold">
                <span className="mb-1 block text-sm leading-6 text-indigo-500">
                  Gaz Kaçak Dedektörü
                </span>
                Gaz Kaçak Dedektörü Nedir?
              </h3>
              <div className="prose prose-slate prose-sm text-slate-600">
                <p>
                  Gaz kaçak dedektörü, tutuşabilir gazları erken algılama
                  sistemine sahip bağımsız bir cihazdır. İç mekandaki gaz
                  sızıntısının yoğunluğunu ölçmek için tasarlanmıştır. Gaz
                  miktarının yoğunluğu, makine skalasında belirtilen değerin
                  üzerine çıkmışsa, gaz dedektörü sesli ve ışıklı uyarı verir.
                  Ancak gaz yoğunluğu eşik değerin altında seyrederse normal
                  çalışma…
                </p>
                <Modal
                  firsth="Gaz Kaçak Dedektörü Nedir?"
                  firstp="Gaz kaçak dedektörü, tutuşabilir gazları erken algılama sistemine sahip bağımsız bir cihazdır. İç mekandaki gaz sızıntısının yoğunluğunu ölçmek için tasarlanmıştır. Gaz miktarının yoğunluğu, makine skalasında belirtilen değerin üzerine çıkmışsa, gaz dedektörü sesli ve ışıklı uyarı verir."
                  secondh="VRF Sistemleri Nasıl Çalışır?"
                  secondp="VRF klima sisteminin çalışma işleyişi sistem içinde bulunur ve her VRF klima sistemi üreticisi tarafından da tescillenir. Sistem, verileri kullanıcıdan ve doğadan alır. Ve bu veriler sayesinde maksimum güç tüketimini uygulayarak ortamın istenilen rahatlık koşullarına ulaşmasını sağlar. Bir kullanıcı tarafından iç ünitelerden biri açık hale getirilince, dış ünite buradan aldığı sinyal ile çalışmaya başlar. Bu durumda, dış ortam şartlarını, iç ortam ihtiyaçlarını inceler ve kompresörü istenilen seviyede çalıştırır.

Başka bir iç ünite açıldığında ise dış ünite çalışan iç ünitelerdeki ihtiyaçları tekrar hesaplar ve istene şartları sağlamak adına kompresörün gücünü arttırır. Yani bu sisten tamimiyle otomatik çalışır. İç üniteden gelen isteğe göre ve dış ortam şartlarına bağlı olarak güç tüketimini iyileştirir."
                />
              </div>
            </div>
            <img
              src="images/10.png"
              alt=""
              className="mb-6 shadow-md rounded-lg bg-slate-50 w-full sm:w-[17rem] sm:mb-0 xl:mb-6 xl:w-full"
              width={1216}
              height={640}
            />
          </li>
          <li className="relative flex flex-col sm:flex-row xl:flex-col items-start">
            <div className="order-1 sm:ml-6 xl:ml-0">
              <h3 className="mb-1 text-slate-900 font-semibold">
                <span className="mb-1 block text-sm leading-6 text-purple-500">
                  Kombi
                </span>
                Kombi Basıncı Neden Düşer?
              </h3>
              <div className="prose prose-slate prose-sm text-slate-600">
                <p>
                  Soğuk havalarda peteklere sıcak su göndererek ısınmamızı
                  sağlayan araçlara kombi denir. Kombiler diğer ısıtma
                  cihazlarına göre daha ekonomik cihazlardır. Kombilerin
                  üzerinde su depolama haznesi bulunmamaktadır. Kombiler, sıcak
                  suyu, içinde bulunan eşanjör adı verilen parça sayesinde anlık
                  olarak üretir. Eşanjörün kendini soğutabilmesi için de kombi
                  içinde…
                </p>
                <Modal
                  firsth="Kombi Basıncı Neden Düşer?"
                  firstp="Soğuk havalarda peteklere sıcak su göndererek ısınmamızı sağlayan araçlara kombi denir. Kombiler diğer ısıtma cihazlarına göre daha ekonomik cihazlardır. Kombilerin üzerinde su depolama haznesi bulunmamaktadır. Kombiler, sıcak suyu, içinde bulunan eşanjör adı verilen parça sayesinde anlık olarak üretir. Eşanjörün kendini soğutabilmesi için de kombi içinde belli bir barda su bulunması gerekir. Kombi içinde bulunması gereken suyun 1,5 ile 2.0 bar arasında olması gerekir."
                  secondh="Kombiye Su Basmak Neden Önemli?"
                  secondp="Bazen doğalgaz kullanılsa bile kombiler ortamı ısıtmaz. Bu durumun çeşitli nedenleri olabilir. Ancak bu nedenlerin en başında kombi su basıncı düşmesi gelir. Kombideki su basıncı düştüğünde, bunu tekrar eski haline almak çok kolay bir işlemdir. Cihazın altında bir mandal vardır ve bu mandal çevrilerek kombi içine su basılır. Bu arada bar göstergesi kontrol edilmeli ve 1.5 ile 2.0 bar arasında kalması sağlanmalıdır. Azalan suyu basarken dikkatli davranılmalıdır. Çok fazla su basılması halinde kombiden su taşabilir. Kombi su basıncının doğru bir şekilde ayarlanması kombinin verimli çalışması için çok önemlidir. Kombi su seviyesinin 2.0 barın üzerine çıkması hemen su taşmasına neden olmayabilir. 1.00’in altında olması ise kombinin hata kodu vererek kendini kapatmasına ve ısınma sorunu yaşamanıza neden olabilir. Kombi bakımı düzenli olarak yapılarak kombilerin verimli bir şekilde çalışması sağlanmalıdır."
                />
              </div>
            </div>
            <img
              src="images/11.png"
              alt=""
              className="mb-6 shadow-md rounded-lg bg-slate-50 w-full sm:w-[17rem] sm:mb-0 xl:mb-6 xl:w-full"
              width={1216}
              height={640}
            />
          </li>
          <li className="relative flex flex-col sm:flex-row xl:flex-col items-start">
            <div className="order-1 sm:ml-6 xl:ml-0">
              <h3 className="mb-1 text-slate-900 font-semibold">
                <span className="mb-1 block text-sm leading-6 text-cyan-500">
                  Doğalgaz
                </span>
                Doğalgaz Nedir? Doğalgazın Keşfi
              </h3>
              <div className="prose prose-slate prose-sm text-slate-600">
                <p>
                  Yüzyıllar önce var olan canlıların kalıntıları yer altında
                  yüksek sıcaklık ve basıncın etkisi ile başkalaşmış böylece
                  doğalgaz oluşmuştur. Genelde kaya boşluklarının arasına
                  sıkışmış bir şekilde veya petrol yataklarının üzerinde bulunan
                  doğalgaz, büyük hacimler halindedir. Doğalgazın bileşiminde
                  %95 metan, etan ve bütan gibi diğer maddeler bulunur….
                </p>
                <Modal
                  firsth="Doğalgaz Nedir? "
                  firstp="Yüzyıllar önce var olan canlıların kalıntıları yer altında yüksek sıcaklık ve basıncın etkisi ile başkalaşmış böylece doğalgaz oluşmuştur. Genelde kaya boşluklarının arasına sıkışmış bir şekilde veya petrol yataklarının üzerinde bulunan doğalgaz, büyük hacimler halindedir. Doğalgazın bileşiminde %95 metan, etan ve bütan gibi diğer maddeler bulunur. Doğalgazı oluşturan bileşenler yeraltındaki petrolünde bileşenleridir. Doğal gaz, eskiden petrol üretimi esnasında rastlanılmış fakat gereksiz görüldüğü için uzaklaştırılmıştır. Daha sonradan önemi bilinmiş ve değerlendirilmiştir. Doğalgaz’ın rengi yoktur. Kokusuz, nemsiz ve havadan hafif bir gazdır. Olası bir gaz kaçağı durumunda fark edilebilmesi için özel olarak kokulandırılmıştır. Doğalgaz karbon monoksit içermediği için zehirli değildir. Fakat olası bir durumda eğer çok fazla solunursa zehirleyebilir. Dünyada 150 trilyon m3 doğalgaz rezervi olduğu bilinmektedir. Fakat bu rezervin sadece %15’i işletilmektedir. Uluslararası politikada önemli bir yere sahip olan doğalgaz, birçok avantajı sayesinde dünyanın en gözde enerji kaynaklarından birisidir."
                  secondh="DOĞALGAZIN KEŞFİ"
                  secondp="Günümüzde kullanımı oldukça yaygın olan doğalgazın keşfi çok eskilere dayanmaktadır. Eski Yunan ve Mısır ülkelerinde gaz tezahürleri asırlar boyunca yanmıştır. Azerbaycan ve Bakü bölgelerinde gaz alevlerinin bulunduğu da bilinmektedir. Doğalgaz yataklarını işletme düşüncesi ise milattan yüzyıllar önce, Çinliler tarafından atılmıştır. Çinliler, tuz çıkartma amacıyla kuyuları açıyor ve gaz kapanlarına rastlıyorlardı. Bunları bambu borularla taşıyarak sokakları aydınlatmada kullanıyorlardı. 17.asırda ise kuzey İtalyanların doğalgazı aydınlatma ve ısıtma amacıyla kullandıkları bilinmektedir. Amerika’ da ilk gaz keşfi, 1815 yılında, West Virginia’daki Charleston bölgesinde tuz madeni civarında olmuştur. 1820 yılında ise ilk ticari gaz işletmeciliği William Hart tarafından New York eyaletinde yapılmıştır.

Doğal gaz, ticari amaçla uzun bir yolculuk yaparak Pitsburg’a getirildi. 1890 yılında Pitsburg’ta doğal gaz dağıtımı için tesis edilen boru hatlarının uzunluğu 750 km’ye ulaştı. İkinci Dünya Savaşı’ndan sonra boru imalatında gelişmeler yaşandı ve hat uzunluğu ve basıncı iki katına çıkarıldı. Kanada kullanım fazlası gazı Amerika’ya, Rusya ise Asya ve Sibirya’dan Batı Rusya ve Doğu Avrupa ülkelerine ihraç etmeye başladı.

1900’lü yıllarda kendi mevcut doğalgazını kullanan ülkeler vardı. Bu ülkeler; Almanya, İtalya, Avusturya ve Fransa’ydı. Hollanda’daki gaz sahasının gelişmesiyle komşu ülkelere gaz ihraç edilmeye başlandı. Almanya’da Hollanda’daki gaz sistemine bağlandı. Fakat artan enerji talebi komşu ülkelerden ya da iç kaynaklardan karşılanamayınca Orta Doğu’dan doğalgazın sıvılaştırılarak tankerlerle nakli başlandı. Böylece doğalgazın yolculuğu, tüm ülkelerde gerek iç kaynaklarından gerek ise komşu ülkelerden alarak günümüze kadar ulaştı."
                />
              </div>
            </div>
            <img
              src="images/12.png"
              alt=""
              className="mb-6 shadow-md rounded-lg bg-slate-50 w-full sm:w-[17rem] sm:mb-0 xl:mb-6 xl:w-full"
              width={1216}
              height={640}
            />
          </li>
        </ul>
      </>
    </div>
  );
};

export default BilgibankContent;
