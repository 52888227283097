import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Cardd from "../components/Cardd";
import ThreeCard from "../components/ThreeCard";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Timeline from "../components/Timeline";
import Ayrac from "../components/Ayrac/Ayrac";

const Anasayfa = () => {
  return (
    <div className="bg-slate-100">
      <Navbar />
      <Hero />
      <Ayrac />
      <Cardd />
      <Timeline />
      <ThreeCard />
      <Contact />
      <Footer />
    </div>
  );
};

export default Anasayfa;
