import { motion } from "framer-motion";
import { useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import useMeasure from "react-use-measure";
import AyracBir from "./Ayrac/AyracBir";

const CARD_WIDTH = 350;
const CARD_HEIGHT = 350;
const MARGIN = 20;
const CARD_SIZE = CARD_WIDTH + MARGIN;

const BREAKPOINTS = {
  sm: 640,
  lg: 1024,
};

const Cardd = () => {
  const [ref, { width }] = useMeasure();
  const [offset, setOffset] = useState(0);

  const CARD_BUFFER =
    width > BREAKPOINTS.lg ? 3 : width > BREAKPOINTS.sm ? 2 : 1;

  const CAN_SHIFT_LEFT = offset < 0;

  const CAN_SHIFT_RIGHT =
    Math.abs(offset) < CARD_SIZE * (items.length - CARD_BUFFER);

  const shiftLeft = () => {
    if (!CAN_SHIFT_LEFT) {
      return;
    }
    setOffset((pv) => (pv += CARD_SIZE));
  };

  const shiftRight = () => {
    if (!CAN_SHIFT_RIGHT) {
      return;
    }
    setOffset((pv) => (pv -= CARD_SIZE));
  };

  return (
    <div>
      <section className="bg-[#b0d4df]" ref={ref}>
        <div className="relative overflow-hidden p-4">
          {/* CARDS */}
          <div className="mx-auto max-w-6xl">
            <h1 className="md:text-4xl text-3xl text-center font-black m-20">
              Hizmetlerimiz
            </h1>
            <motion.div
              animate={{
                x: offset,
              }}
              className="flex"
            >
              {items.map((item) => {
                return <Card key={item.id} {...item} />;
              })}
            </motion.div>
          </div>

          {/* BUTTONS */}
          <>
            <motion.button
              initial={false}
              animate={{
                x: CAN_SHIFT_LEFT ? "0%" : "-100%",
              }}
              className="absolute left-0 top-[60%] z-30 rounded-r-xl bg-slate-100/30 p-3 pl-2 text-4xl text-white backdrop-blur-sm transition-[padding] hover:pl-3"
              onClick={shiftLeft}
            >
              <FiChevronLeft />
            </motion.button>
            <motion.button
              initial={false}
              animate={{
                x: CAN_SHIFT_RIGHT ? "0%" : "100%",
              }}
              className="absolute right-0 top-[60%] z-30 rounded-l-xl bg-slate-100/30 p-3 pr-2 text-4xl text-white backdrop-blur-sm transition-[padding] hover:pr-3"
              onClick={shiftRight}
            >
              <FiChevronRight />
            </motion.button>
          </>
        </div>
      </section>
      <AyracBir />
    </div>
  );
};

const Card = ({ url, category, title, description }) => {
  return (
    <div
      className="relative shrink-0 cursor-pointer rounded-2xl mb-10 bg-white shadow-md transition-all hover:scale-[1.015] hover:shadow-xl"
      style={{
        width: CARD_WIDTH,
        height: CARD_HEIGHT,
        marginRight: MARGIN,
        backgroundImage: `url(${url})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="absolute inset-0 z-20 rounded-2xl bg-gradient-to-b from-black/90 via-black/60 to-black/0 p-6 text-white transition-[backdrop-filter] hover:backdrop-blur-sm">
        <p className="my-2 text-3xl font-bold">{title}</p>
        <p className="text-lg text-slate-300">{description}</p>
      </div>
    </div>
  );
};

export default Cardd;

const items = [
  {
    id: 1,
    url: "https://www.dogalgazsistemleri.com/wp-content/uploads/2022/09/yerden-isitma.jpg",
    title: "Yerden Isıtma",
    description:
      "Yerden ısıtma günümüzde en konforlu ısınma şekillerinden biridir.",
  },
  {
    id: 2,
    url: "https://www.dogalgazsistemleri.com/wp-content/uploads/2023/02/isi-pompasi-3.jpg",
    title: "Isı Pompası",
    description:
      "Isı pompaları, geleceğin iklimlendirme teknolojisi ürünlerinden biri kabul edilmektedir.",
  },
  {
    id: 3,
    url: "https://www.dogalgazsistemleri.com/wp-content/uploads/2022/09/kombi-radyator-e1664435453137.jpg",
    title: "Doğalgaz Tesisatı",
    description:
      "Doğru doğalgaz tesisatı uzun ömürlü ve güvenli kullanım sağlar.",
  },
  {
    id: 4,
    url: "https://anzteknoloji.com/wp-content/uploads/2023/02/vrv-vrf-sistem.webp",
    title: "Vrf Klima Sistemleri",
    description:
      "VRF klima sistemi, değişken akışkan debili sistemlerdir. Yani sistem, içinde tuttuğu akışkanın debisini değiştirerek gereken kapasiteyi tam ve net olarak ayarlayabilir.",
  },
  {
    id: 5,
    url: "https://kazankaskad.com/storage/2016/01/buderus-kazan.png",
    title: "Kaskad Kazan Sistemleri",
    description:
      "Kaskad sistem, birden fazla kazanın kontrol modülü aracılığı ile senkronize olarak çalıştığı ısıtma sistemidir.",
  },
  {
    id: 6,
    url: "https://inframak.com/Content/uploads/kafe%20montaj2%20-%20Copy%202.jpg",
    title: "Radyant Isı Sistemleri",
    description:
      "Delta Isı Sistemleri olarak birçok kafe, restoran ve açık hava alanlarında gözlemlediğimiz kadarı ile bu mekanların en verimli ve en ekonomik şekilde ısısının değiştirilebileceğine dair birçok çözümümüz var!",
  },
  {
    id: 7,
    url: "https://www.dogalgazsistemleri.com/wp-content/uploads/2022/09/dogalgaz-tesisati-e1664434594751.jpg",
    title: "Kombi & Radyatör",
    description: "Kaliteli kombi ve radyatör tesisatı için bize ulaşın.",
  },
  {
    id: 8,
    url: "https://ideacdn.net/idea/bx/35/myassets/products/859/fhs-9000-mfc.jpg?revision=1715080942",
    title: "Doğalgaz Sobası",
    description:
      "Doğalgaz sobası, tesisat üzerinden gelen gazı krom alaşım özeliğine sahip brolür desteğiyle sıkıştıran ve bulunduğu bölgenin ısınmasını sağlayan ısıtıcı türüdür.",
  },
];
