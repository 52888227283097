import React from "react";

const BilgibankHero = () => {
  return (
    <div>
      <div
        style={{
          background:
            "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(https://static.vecteezy.com/system/resources/previews/001/895/642/non_2x/question-mark-on-blue-background-paper-art-style-vector.jpg) no-repeat center",
          backgroundSize: "Cover",
        }}
        className="py-64 px-1 md:px-8 text-center relative text-white font-bold text-2xl md:text-3xl overflow-auto"
      >
        <h1 className="text-3xl font-bold text-white sm:text-4xl md:text-5xl lg:text-6xl">
          Bilgi Bankası
        </h1>
      </div>
    </div>
  );
};

export default BilgibankHero;
