import React from "react";
import Navbar from "../components/Navbar";
import HizmetHero from "../components/Hizmetlerimiz/HizmetHero";
import HizmetContent from "../components/Hizmetlerimiz/HizmetContent";
import Footer from "../components/Footer";

const Hizmetlerimiz = () => {
  return (
    <>
      <Navbar />
      <HizmetHero />
      <HizmetContent />
      <Footer />
    </>
  );
};

export default Hizmetlerimiz;
