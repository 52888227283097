import React from "react";
import { GrUserWorker } from "react-icons/gr";
import { CiClock1 } from "react-icons/ci";
import { FaScrewdriverWrench } from "react-icons/fa6";
import { GiMoneyStack } from "react-icons/gi";

const ThreeCard = () => {
  return (
    <div>
      <h1 className="md:text-4xl text-3xl text-center font-black mt-10 m-10">
        Prensiplerimiz
      </h1>
      <div class="grid gap-1 md:grid-cols-4 lg:gap-12 p-6 md:p-10  bg-slate-100">
        <div className="relative flex flex-col justify-center overflow-hidden py-6 sm:py-12">
          <div className="group relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10">
            <span className="absolute top-10 z-0 h-20 w-20 rounded-full bg-[#145d7a] transition-all duration-300 group-hover:scale-[10]" />
            <div className="relative z-10 mx-auto max-w-md">
              <span className="grid h-20 w-20 place-items-center rounded-full bg-[#145d7a] transition-all duration-300 group-hover:bg-[#4897ab]">
                <GrUserWorker className="w-8 text-cyan-100 h-8" />
              </span>
              <div className="space-y-6 pt-5 text-base leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
                <h1 className="text-2xl font-black">Profesyonel Ekip</h1>
                <p>
                  İşinde uzman ekibimizle her çağrınıza kulak veriyor, tesisatla
                  ilgili her probleminize akılcı çözümler üretiyoruz.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex flex-col justify-center overflow-hidden py-6 sm:py-12">
          <div className="group relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10">
            <span className="absolute top-10 z-0 h-20 w-20 rounded-full bg-[#145d7a] transition-all duration-300 group-hover:scale-[10]" />
            <div className="relative z-10 mx-auto max-w-md">
              <span className="grid h-20 w-20 place-items-center rounded-full bg-[#145d7a] transition-all duration-300 group-hover:bg-[#4897ab]">
                <CiClock1 className="h-8 w-8 text-cyan-100" />
              </span>
              <div className="space-y-6 pt-5 text-base leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
                <h1 className="text-2xl font-black">1 Günde İş Teslimi</h1>
                <p>
                  Deneyimli ekibimiz tarafından hızlı bir şekilde projenizin
                  montaj ve tesisatı 1 gün içinde hasarsız teslim edilir.
                </p>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="relative flex  flex-col justify-center overflow-hidden py-6 sm:py-12">
          <div className="group relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10">
            <span className="absolute top-10 z-0 h-20 w-20 rounded-full bg-[#145d7a] transition-all duration-300 group-hover:scale-[10]" />
            <div className="relative z-10 mx-auto max-w-md">
              <span className="grid h-20 w-20 place-items-center rounded-full bg-[#145d7a] transition-all duration-300 group-hover:bg-[#4897ab]">
                <FaScrewdriverWrench className="h-8 w-8 text-cyan-100" />
              </span>
              <div className="space-y-6 pt-5 text-base leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
                <h1 className="text-2xl font-black">Kaliteli İşçilik</h1>
                <p>
                  Kaliteli işçiliğimizle sektörde daima en öndeyiz. Kaliteli
                  işçiliğimizi sizinle ve evinizle buluştuyoruz.
                </p>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="relative flex  flex-col justify-center overflow-hidden py-6 sm:py-12">
          <div className="group relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10">
            <span className="absolute top-10 z-0 h-20 w-20 rounded-full bg-[#145d7a] transition-all duration-300 group-hover:scale-[10]" />
            <div className="relative z-10 mx-auto max-w-md">
              <span className="grid h-20 w-20 place-items-center rounded-full bg-[#145d7a] transition-all duration-300 group-hover:bg-[#4897ab]">
                <GiMoneyStack className="h-8 w-8 text-cyan-100" />
              </span>
              <div className="space-y-6 pt-5 text-base leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
                <h1 className="text-2xl font-black">Uygun Ödeme Seçenekleri</h1>
                <p>
                  Her bütçeye uygun ödeme yöntemi ve seçenekleri ile sizlere
                  yardımcı olarak, en iyi fiyat garantisini sunarız.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreeCard;
