import React from "react";

const TeklifHero = () => {
  return (
    <div>
      <div
        style={{
          background:
            "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(./images/teklif.jpg) no-repeat center",
          backgroundSize: "Cover",
        }}
        className="py-64 px-1 md:px-8 text-center relative text-white font-bold text-2xl md:text-3xl overflow-auto"
      >
        <h1 className="text-3xl font-bold text-white sm:text-4xl md:text-5xl lg:text-6xl">
          Teklif Al
        </h1>
      </div>
    </div>
  );
};

export default TeklifHero;
